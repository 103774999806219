<template>
  <FullScreenDialog
    v-model="operatorDialog"
    title="Operatori"
    :routeFather="pathToGoBack"
    :routeFatherName="pathName"
    :otherGoBackParams="{ params: { filters: this.filters } }"
  >
    <template v-slot:toolbar-extension>
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab
          v-for="tab in operatorTabs"
          :key="tab.key"
        >{{ tab.title }}<span v-if="tab.suffix" class="dot"></span></v-tab>
      </v-tabs>
    </template>
    <template v-slot:content>
    <v-row>
      <v-col justify="right" align="right">
        <v-btn 
          text
          class="mr-2 mt-2"
          @click="printOperator"
        > Stampa Operatore 
          <v-icon class="ml-2"> mdi-printer </v-icon>
        </v-btn>
      </v-col>
    </v-row>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="tabItem in operatorTabs" :key="tabItem.key">
          <v-card flat>
            <component :is="tabItem.component"></component>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>
    <template v-slot:footer-actions>
      <v-spacer></v-spacer>
      <v-btn
        text
        color="error"
        :disabled="loading"
        @click="close"
      >Annulla</v-btn>
      <v-btn
        text
        color="default"
        :loading="loading"
        :disabled="!formValid || isLicenseBlocked"
        @click="submitForm"
      >Salva</v-btn>
    </template>
  </FullScreenDialog>
</template>

<script>
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import RegistryForm from "@/components/areas/registries/operators/form/RegistryFormTab.vue"
import EnablingForm from "@/components/areas/registries/operators/form/EnablingFormTab.vue"
import enablingService from '@/services/enablings/enablings.service.js'
import operatorService from "@/services/operators/operators.service.js"
import operatorForm from '@/services/operators/operator.form.js'

export default {
  name: "OperatorRegistryEditForm",
  components: {
    FullScreenDialog,
    RegistryForm,
    EnablingForm
  },
  data: function() {
    return {
      tab: null,
      id: undefined,
      operatorDialog: this.openDialog,
      qualificationIds: undefined,
      operatorTabs: [
        {
          key: 0,
          title: "Anagrafica",
          icon: "mdi-book",
          name: "registry",
          suffix: false,
          component: "RegistryForm"
        },
        {
          key: 1,
          title: "Abilitazioni",
          name: "enablings",
          suffix: false,
          icon: "mdi-book-plus-outline",
          component: "EnablingForm"
        }
      ],
      formValid: true,
      loading: false,
      operator: undefined,
      hasEnabling: false,
      originalUrl: window.location.pathname,
      isLicenseBlocked: undefined
    };
  },
  mounted() {
    this.changeTabBasedOnProp(this.tabName)
    
    this.id = this.$route.params.id
    if(this.id) {
      this.fetchOperator()
    }
    
    let self = this
    operatorForm.on('update', function(data) {
      if (!data.operator.enablingServices || data.operator.enablingServices.length === 0)
        self.operatorTabs[1].suffix = true
      else 
        self.operatorTabs[1].suffix = false
      self.operator = data.operator
    })

    self.valid = operatorForm.valid
    operatorForm.on('valid-change', function(valid) {
      self.formValid = valid
    })
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false
    },
    pathToGoBack: {
      type: String,
      default: "/registries"
    },
    pathName: {
      type: String,
    },
    tabName: {
      type: String,
      default: 'registry'
    },
    filters: {
      type: Array,
    },
  },
  methods: {
    changeTabBasedOnProp(tabName) {
      for(let i = 0; i < this.operatorTabs.length; i++) {
        if(this.operatorTabs[i].name == tabName) {
          this.tab = i
          break
        }
      }
    },
    submitForm() {
      this.loading = true
      if (!this.operator.enablingServices || this.operator.enablingServices.length === 0) {
        this.$delegates.snackbar("Attenzione! Scegliere almeno un'abilitazione")
        this.loading = false
      }
      else {
        delete this.operator.password
        delete this.operator.avatar
        operatorService.update(this.operator).then((result) => {
          this.close()
          this.loading = false
        }).catch((error) => {
          if(error.message == "Code Already Present") {
            this.$delegates.snackbar("Barcode già presente")
            this.loading = false
          }
          else {
            this.close()
            this.loading = false
          }
        })
      }
    },
    close() {
      if(!!this.pathName)
        this.$router.push({name: this.pathName, params: { filters: this.filters }})
      else
        this.$router.push({path:this.pathToGoBack})
    },
    fetchOperator() {
      this.loadingOperator = true

      operatorForm.resetOperator()
      operatorService.get(this.id).then((operator) => {

        if (!operator.textColor) {
          operator.textColor = '#ffffff'
        }

        if (!operator.color) {
          operator.color = '#000000'
        }

        enablingService.enablingTypeOfOperator(operator).then((enablingServices) => {
          operator.enablingServices = enablingServices
          if (!operator.enablingServices || operator.enablingServices.length === 0)
            this.operatorTabs[1].suffix = true
          else 
            this.operatorTabs[1].suffix = false
          
          this.loadingOperator = false
          operatorForm.updateObject(operator)
          
          this.qualificationIds=Number(operator.qualificationOperators.qualificationId)
          
          operator.qualificationOperators = this.qualificationIds     
        })
      })
    },
    printOperator() {
      operatorService
        .print(this.operator.id, "Scheda_Anagrafica_Operatore_" + this.operator.firstname + "_" + this.operator.lastname)
        .then((result) => {})
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {
    openDialog(newVal) {
      this.operatorDialog = newVal;
    },
    tab(newVal) {
      try {
        window.history.pushState("Tab", "Tab", this.originalUrl + '?tabName=' + this.operatorTabs[newVal].name);
      } catch(err) {
        console.log('try setting the tab name but something goes wrong')
      }
    },
  },
  isLicenseBlocked: {
    bind: 'isLicenseBlocked'
  }
};
</script>

<style>
  .dot {
      height: 0.8em;
      width: 0.8em;
      background-color: rgb(219, 2, 2);
      border-radius: 50%;
      float: right;
      margin-bottom: 2em;
      margin-right: 2em;
    }
</style>